import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Accordion,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { useHistory } from "react-router";
import SelectInputCached from "../components/UI/Formik/SelectInput/SelectInput.cached";
import { AppContext } from "../context/AppContext";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import { FormatDate } from "../components/UI/Format/Format";
import appConfig from "../config/app.config";

const initialValues = {
  idOrgao: 0,
  idArea: 0,
};

const ManifestacoesInteresseSociais = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const { request, data, errors, loading, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSpinnerhandleLoadMore, setshowSpinnerhandleLoadMore] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(10);
  const [PageOffset, setPageOffset] = useState(0);
  const [TotalItens, setTotalItens] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const tipo = "MIS";

  useEffect(() => {
    setBackgroundClassList(["background-3"]);
  }, [setBackgroundClassList]);

  const FiltrarPesquisa = (values) => {
    setShowSpinner(true);
    setPageOffset(0)
    request(apiRoutes.main.propostas.pesquisar,
      {
        routeParams: {
          limit: itemsToShow,
          offset: 0,
          idorgao: formik.values.idOrgao,
          idarea: formik.values.idArea,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: FiltrarPesquisa,
  });


  useEffect(() => {
    request(apiRoutes.main.propostas.listagemPublica,
      {
        routeParams: {
          limit: itemsToShow,
          offset: PageOffset
        }
      },
      { requestId: "carregar" }
    );
  }, [request]);

  const limparCampos = () => {
    setShowSpinner(true);
    setPageOffset(0)
    formik.setValues({
      idOrgao: 0,
      idArea: 0,
    });
    request(apiRoutes.main.propostas.listagemPublica,
      {
        routeParams: {
          limit: itemsToShow,
          offset: 0
        }
      },
      { requestId: "limpar" }
    )
  };


  useEffect(() => {

    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);
    }

    if (data && requestId === "pesquisar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);
    }

    if (data && requestId === "limpar") {
      setTimeout(() => {
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setPaginatedData(data);
        setShowSpinner(false);
      }, 1000);
    }
  }, [data, requestId]);


  const getStatusClass = (id) => {
    switch (id) {
      case 1:
        return "bnt-ativo rounded-pill btn-round bnt-status";
      case 2:
        return "bnt-encerrado rounded-pill btn-round bnt-status";
      case 3:
        return "bnt-aguardando rounded-pill btn-round bnt-status";
      case 4:
        return "bnt-parceria_em_andamento rounded-pill btn-round bnt-status";
      case 5:
        return "bnt-chamamento-aberto-para-impugnacao rounded-pill btn-round bnt-status";
      case 6:
        return "bnt-chamamento_fechado_para_impugnacao rounded-pill btn-round bnt-status";
      case 7:
        return "bnt-encerrado_dipensa_mantida rounded-pill btn-round bnt-status";
      case 8:
        return "bnt-encerrado_dipensa_revogada rounded-pill btn-round bnt-status";
      case 9:
        return "bnt-encerrado rounded-pill btn-round bnt-status";
      case 10:
        return "bnt-cancelado rounded-pill btn-round bnt-status";
      default:
        return "";
    }
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    const newOffset = PageOffset + 10;
    setshowSpinnerhandleLoadMore(true);
    if (requestId === "pesquisar") {
      fetch(`${appConfig.host}/api/proposta/pesquisar?limit=${itemsToShow}&offset=${newOffset}&idorgao=${formik.values.idOrgao}&idarea=${formik.values.idArea}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    } else {
      fetch(`${appConfig.host}/api/proposta/listar/proposta/publica?limit=${itemsToShow}&offset=${newOffset}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    }
  };


  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Manifestações de Interesses Sociais
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
            <Accordion className="mt-5">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="w-100 text-left"
                  >
                    O que é um procedimento de Manifestação de Interesse Social?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p className="text-justify mt-2">
                      O Procedimento de Manifestação de Interesse Social (PMIS)
                      é o instrumento pelo qual as organizações da sociedade
                      civil, movimentos sociais e cidadãos poderão apresentar
                      propostas à Administração Pública, para que esta avalie a
                      possibilidade de realização de um chamamento público com o
                      objetivo de eventualmente celebrar parceria.
                    </p>
                    <p className="text-justify mt-1">
                      É uma iniciativa presente na Lei Federal nº 13.019/14,
                      incentivando a presença da sociedade civil no
                      desenvolvimento de políticas públicas e de parcerias
                      administrativas, fortalecendo os meios de participação
                      direta da população e das organizações civis.
                    </p>
                    <p className="text-justify mt-1">
                      A Manifestação de Interesse Social (MIS) deve estar
                      relacionada com ações de interesses públicos e coletivos,
                      sendo incabível para proposição de demandas individuais,
                      promovendo, especialmente, os direitos sociais, como
                      aqueles expostos no art.6º da Constituição Federal de
                      1988:{" "}
                      <em>
                        “a educação, a saúde, a alimentação, o trabalho, a
                        moradia, o transporte, o lazer, a segurança, a
                        previdência social, a proteção à maternidade e à
                        infância, a assistência aos desamparados”
                      </em>
                      , entre outros.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="w-100 text-left"
                  >
                    Como uma OSC pode submeter propostas de manifestação de
                    interesse social?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p className="text-justify mt-2">
                      Os requisitos para elaboração das propostas e seu
                      encaminhamento para a Administração Pública estão
                      presentes no art. 19 da Lei Federal nº 13.019/2014, assim,
                      havendo interesse, qualquer cidadão, movimento ou
                      organização da sociedade civil poderá enviar a proposta em
                      plataforma disponibilizada pela Administração Pública
                      Estadual, devendo conter:
                    </p>
                    <ul>
                      <li className="mt-3">
                        I - identificação do subscritor da proposta;
                      </li>
                      <li className="mt-3">
                        II - indicação do interesse público envolvido;
                      </li>
                      <li className="mt-3">
                        III - diagnóstico da realidade que se quer modificar,
                        aprimorar ou desenvolver e, quando possível, indicação
                        da viabilidade, dos custos, dos benefícios e dos prazos
                        de execução da ação pretendida.
                      </li>
                    </ul>
                    <p className="text-justify mt-1">
                      Recebida a(s) proposta(s) a Administração Pública Estadual
                      irá publicá-la(s) em seu sítio eletrônico e analisará a{" "}
                      <b>conveniência</b> e <b>oportunidade</b> para realização
                      do Procedimento de Manifestação de Interesse Social.
                    </p>
                    <p className="text-justify mt-1">
                      Se a(s) proposta(s) forem analisadas favoravelmente, a
                      Administração Pública Estadual dará início ao Procedimento
                      de Manifestação de Interesse Social e realizará a oitiva
                      da sociedade sobre o tema proposto, através de audiências
                      públicas, consultas públicas, seminários, e outros
                      mecanismos de participação.
                    </p>
                    <p className="text-justify mt-1">
                      A Organização da Sociedade Civil (OSC) que apresentar
                      proposta ou participar do Procedimento de Manifestação de
                      Interesse Social{" "}
                      <b>
                        não terá sua participação impedida em eventual
                        chamamento público decorrente do PMIS
                      </b>
                      .
                    </p>
                    <p className="text-justify mt-1">
                      É ainda importante lembrar que:
                    </p>
                    <ul>
                      <li className="mt-3">
                        (a) A realização do Procedimento de Manifestação de
                        Interesse Social <b>não</b> implicará necessariamente na
                        execução do chamamento público, que acontecerá de acordo
                        com os interesses da administração.
                      </li>
                      <li className="mt-3">
                        (b) A realização do Procedimento de Manifestação de
                        Interesse Social <b>não</b> poderá ser utilizada para
                        dispensar o chamamento público na celebração de
                        parceria.
                      </li>
                      <li className="mt-3">
                        (c) <b>É vedado</b> condicionar a realização de
                        chamamento público ou a celebração de parceria à prévia
                        realização de Procedimento de Manifestação de Interesse
                        Social.
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col className="text-center my-4 mb-5">

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Enviar proposta</Tooltip>}>
              <Button
                variant="primary"
                onClick={() => history.push("/minha-area/proposta")}
              >
                Faça sua proposta
              </Button>
            </OverlayTrigger>

          </Col>
        </Row>

        <Row>
          <Col className="bg-light p-3">
            <Form>
              <Row>
                <Col className="mt-4" lg="6" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="idArea"
                    label="Área de Atuação:"
                    cacheKey="areas"
                    allowEmpty
                  />
                </Col>

                <Col className="mt-4" lg="6" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="idOrgao"
                    label="Secretaria ou Autarquia:"
                    cacheKey="orgaos"
                    allowEmpty
                  />
                </Col>
              </Row>
              <Col className="d-flex flex-row mt-5 justify-content-center">

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Filtrar pesquisa</Tooltip>}>
                  <Button
                    variant="primary"
                    className="me-3"
                    onClick={formik.handleSubmit}
                  >
                    Pesquisar
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Limpar resultados e filtros da pesquisa</Tooltip>}>
                  <Button variant="link" className="ms-3" onClick={limparCampos} disabled={loading}>
                    Limpar
                  </Button>
                </OverlayTrigger>

              </Col>
            </Form>
          </Col>
        </Row>
        {showSpinner && (
          <Row>
            <Col className="d-flex flex-row mt-5 justify-content-center">
              <h5 className="mb-2">
                <Spinner animation="border" size="lg" variant="warning" />
              </h5>
            </Col>
          </Row>
        )}
        {!loading && !showSpinner && data && data.length === 0 && (
          <Alert className="mt-3" variant="primary">
            Nenhum registro encontrado
          </Alert>
        )}
        {!loading &&
          data &&
          !showSpinner &&
          paginatedData.map((item) => (
            <Row className="mt-3" key={item.id_proposta}>
              <Col>
                <Card className="card-border mt-3">
                  <Card.Img variant="top" />
                  <Card.Body>
                    <Row className="justify-content-between">
                      <Col lg="9" md="12" sm="12" >
                        <Card.Title className="h4">{item.tx_orgao} </Card.Title>
                        <Card.Subtitle className="h5 mt-2 mb-2">{item.tx_titulo} </Card.Subtitle>
                        <Card.Text className="mt-3 mb-3">{item.tx_descricao}</Card.Text>
                      </Col>
                      <Col

                        lg="3"
                        md="12"
                        sm="6"

                        className=" d-flex flex-column align-items-center "
                      >
                        <Col className="d-flex flex-column align-items-center justify-content-start ">
                          <Card.Subtitle>Publicação da proposta:</Card.Subtitle>
                          <Card.Text className="description mb-1 text-center">
                            {FormatDate(item.tm_inclusao)}
                          </Card.Text>
                          <Card.Subtitle className="mt-1">Área de atuação:</Card.Subtitle>
                              <Card.Text className="description mb-1  text-center">
                                {item.areaoutros ? item.areaoutros : item.area }
                              </Card.Text>

                          <Card.Text className="mt-2 d-flex mb-3">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium">{"Status da parceria" + " " + item.tx_status}</Tooltip>}
                            >
                              <span className={`${getStatusClass(item.id_status)}`}>
                                {item.tx_status + " "}
                              </span>
                            </OverlayTrigger>
                          </Card.Text>
                        </Col>
                      </Col>
                      <Card.Text>
                        <Button variant="link" onClick={(e) => {
                          e.preventDefault()
                          history.push(`/listar/arquivos-andamentos/${tipo}/${item.id_proposta}`)
                        }}>
                          Visualizar arquivos e andamentos.
                        </Button>
                      </Card.Text>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
        {showSpinnerhandleLoadMore && (
          <Row>
            <Col className="d-flex flex-row mt-2 justify-content-center">
              <h5 className="mb-2">
                <Spinner animation="border" size="lg" variant="warning" />
              </h5>
            </Col>
          </Row>
        )}
        {data && paginatedData.length < TotalItens && !showSpinner && !showSpinnerhandleLoadMore && (
          <div className="d-flex justify-content-center">
            <Button onClick={handleLoadMore}>Carregar mais</Button>
          </div>
        )}
      </Container>
    </>
  );
};

export default ManifestacoesInteresseSociais;
