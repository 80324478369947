import React, { useContext, useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import * as yup from "yup";
import TextInput from "../components/UI/Formik/TextInput/TextInput";
import useApiClient, { apiRoutes } from "../hooks/apiClient";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import SpinnerLabel from "../components/UI/SpinnerLabel";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const asciiEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .max(100, 'O  e-mail  não pode ter mais de 100 caracteres')
    .email("E-mail inválido, verifique seu email.")
    .matches(asciiEmailRegex, 'O e-mail não deve conter caracteres especiais, como símbolos, acentos (á, ç, ü, etc.), letras fora do padrão inglês e espaço em branco.'),
});

const initialValues = {
  email: "",
};

const PaginaEnvioLinkCadastroUsuario = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.usuario.criar, values);
    },
  });

  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (data && !errors) {
        toast.success('E-mail enviado com Sucesso!!! Acesse seu e-mail para continuar seu cadastro.', {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
          onClose: () => {
            setSubmitting(false);
            history.push("/");
          }
        });}
      
    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
        });
    }

  }, [data, errors, history]);

  const readOnly = loading || formik.isSubmitting;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Cadastro
            </h1>
          </Col>
        </Row>
      </Container>
      <div id="conteudo-pagina-w100" style={{ marginTop: 200 }}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <Col className="col-md-6 p-2">
                <Form onSubmit={formik.handleSubmit}>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <p className="text-center">
                    Cidadão, insira seu e-mail para criar um novo usuário. Um link com as instruções será enviado.
                    </p>
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12">
                    <TextInput
                      formik={formik}
                      field="email"
                      label="E-mail:"
                      placeholder="Informe o seu e-mail"
                      readOnly={readOnly}
                      disabled={isSubmitting}
                    />
                  </Col>

                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="d-flex justify-content-center mt-5"
                  >
                    <Button
                      variant="primary"
                      onClick={formik.handleSubmit}
                      disabled={isSubmitting}
                    >
                      <SpinnerLabel label="Enviar" loading={readOnly} />
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaginaEnvioLinkCadastroUsuario;
