import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Alert, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { FormatDate } from "../../../components/UI/Format/Format";
import { FaUserAltSlash } from "react-icons/fa";
import TabelPagination from "../../../components/pagination/TabelPaginatio";
import ConfirmarExclusaoModal from "../../../components/Modal/ConfirmarExclusaoModal";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached.js";
import { FaUserPen } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";


const initialValues = {
  IdSetor: 0,
};


const SalvarPesquisa = {
  IdSetor: 0,
};

const ListagemCoordenadores = () => {
  const { request, data, errors, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [IdDeleteCoordenador, setIdDeleteCoordenador] = useState(null);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const itemsPerPage = 5;



  useEffect(() => {
    request(apiRoutes.main.coordenador.listarCordenadores,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request,  offset]);


  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.coordenador.pesquisarCoordenador,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            IdSetor: SalvarPesquisa.IdSetor,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);


  const FiltrarPesquisa = (values) => {
    SalvarPesquisa.IdSetor = values.IdSetor;
    setlimparPesquisa(false);
    request(apiRoutes.main.coordenador.pesquisarCoordenador,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          IdSetor: SalvarPesquisa.IdSetor,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true)
    formik.setValues({
      IdSetor: 0,
    });
    SalvarPesquisa.IdSetor = 0;
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.coordenador.listarCordenadores,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: 0
        }
      },
      { requestId: "carregar" }
    );
  };

  useEffect(() => {

    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }

    if (data && requestId === "remover") {
      toast.success("Coordenador desativado com sucesso!!!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload();
        },
      });
    }

    if (errors) {
      toast.error(errors.message || "Desculpe, algo deu errado. Tente novamente mais tarde.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }
  }, [data, requestId])


  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setShowSpinner(false);
  };

  const handleDeleteConfirm = () => {
    if (IdDeleteCoordenador) {
      request(
        apiRoutes.main.coordenador.removerCoordenador,
        {
          routeParams: { id: IdDeleteCoordenador },
        },
        { requestId: "remover" }
      );
    }
    setShowModal(false);
  };

  return (
    <>
      <Container className="conteudo-pagina mt-5 mb-5">
        <Row>
          <Col>
            <h3 className="mt-4 fw-bold">Coordenadores Cadastrados</h3>
          </Col>
        </Row>


        <Row>
          <Col>
            <p>
            Nesta seção, são listados todos os coordenadores cadastrados e ativos no portal OSC. Para desativar um coordenador, clique no ícone de usuário localizado na coluna "Desativar". Note que a desativação de um usuário é um processo irreversível.
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="7" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="IdSetor"
                    label="Pesquisar coordenador por setor"
                    cacheKey="setoresSecretariaDoUsuario"
                    defaultText="Selecione um setor"
                  />
                </Col>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                    <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                      Pesquisar
                    </Button>
                  </OverlayTrigger>


                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                    <Button variant="link" className="ms-3" onClick={limparCampos}>
                      Limpar
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            {showSpinner && (
              <Row>
                <Col className="d-flex flex-row mt-5 justify-content-center">
                  <h5 className="mb-2">
                    <Spinner
                      animation="border"
                      size="lg"
                      variant="warning"
                    />
                  </h5>
                </Col>
              </Row>
            )}

            {!showSpinner && data && data.length === 0 && (
              <Alert className="mt-3" variant="primary">Nenhum registro encontrado</Alert>
            )}
            {paginatedData && !showSpinner && paginatedData.length > 0 && (
              <>
                <div style={{ overflowX: 'auto' }}>
                  <Table striped bordered hover className="mt-4">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Setor</th>
                        <th>Último acesso</th>
                        <th>Desativar</th>
                        <th>Reenviar link de ativação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData && Array.isArray(paginatedData) && paginatedData.map((itemData) => (
                        <tr key={itemData.id_usuario_dados}>
                          <td>{itemData.tx_nome}</td>
                          <td>{itemData.tx_login}</td>
                          <td>{itemData.setor}</td>
                          <td>{FormatDate(itemData.tm_ultimo_acesso) ? FormatDate(itemData.tm_ultimo_acesso) : "O usuário ainda não realizou o primeiro acesso."}</td>
                          <td className="text-center mt-2">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium mt-1">Desativar Usuário</Tooltip>}>
                              <FaUserAltSlash
                                style={{ cursor: "pointer" }}
                                size={20}
                                color="red"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowSpinner(true);
                                  setIdDeleteCoordenador(itemData.id_usuario_servidor);
                                  setShowModal(true);
                                }}
                              />
                            </OverlayTrigger>
                          </td>
                          <td className="text-center mt-2">
                              {!itemData.tm_token_validacao ?(
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip className="medium mt-1 ">Acessar página de reenvio de link para ativação cadastral</Tooltip>}>
                                        <Link to={`/reenviar/link/user/${itemData.id_usuario_dados}`}>
                                          <FaUserPen
                                            style={{ cursor: "pointer" }}
                                            size={23}
                                            color="blue"
                                          />
                                          </Link>
                                   </OverlayTrigger>
                                    ) : (
                                       <OverlayTrigger
                                          placement="bottom"
                                          overlay={<Tooltip className="medium mt-1">Usuário Ativado</Tooltip>}>
                                          <FaUserCheck
                                            size={23}
                                            color="green"
                                          />
                                        </OverlayTrigger>
                                                        )}
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {data && totalPages > 1 && (
                  <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />)}
              </>
            )}

          </Col>
        </Row>
      </Container>
      <ConfirmarExclusaoModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleConfirm={handleDeleteConfirm}
        handleCancel={handleCancelDelete}
        title="Confirmar desativação de conta"
        bodyText="Atenção: A desativação de um usuário é um processo irreversível. Você tem certeza de que deseja prosseguir com a desativação deste usuário?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
      />
    </>
  );
};

export default ListagemCoordenadores;
