import React, { useEffect, useState } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Card,
  Col,
  Row,
  Form,
  Container,
  Alert,
  Spinner
} from "react-bootstrap";
import {
  AiOutlineExclamationCircle,
  AiOutlineCopy,
} from "react-icons/ai";
import moment from 'moment';
import { FormatarSEI } from "../../components/UI/Format/Format";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { useHistory } from "react-router";
import appConfig from "../../config/app.config";
import Inputmask from "inputmask";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  ProcessoSei: yup.string().transform((value) => value.replace(/[^0-9]/g, '')) 
  .length(20, "O Número do processo SEI deve ter exatamente 20 caracteres"),
});

const initialValues = {
  ProcessoSei: "",
};

const TermosEAcordosLista = () => {
  const { request, data, errors, loading, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSpinnerhandleLoadMore, setshowSpinnerhandleLoadMore] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(10);
  const [PageOffset, setPageOffset] = useState(0);
  const [TotalItens, setTotalItens] = useState(0);
  const history = useHistory();
  const tipo = "termo";


  useEffect(() => {

    request(apiRoutes.main.termosAcordos.listarTermosAcordosPublico,
      {
        routeParams: {
          limit: itemsToShow,
          offset: PageOffset
        }
      },
      { requestId: "carregar" }
    )
  }, [request]);

  useEffect(() => {
    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("ProcessoSei"));
  }, []);


  const FiltrarPesquisa = (values) => {
    values.ProcessoSei = values.ProcessoSei.replace(/[^\d]+/g, "");
    setShowSpinner(true);
    setPageOffset(0)
    request(apiRoutes.main.termosAcordos.PublicoPesquisarTermosAcordos,
      {
        routeParams: {
          limit: itemsToShow,
          offset: 0,
          sei: values.ProcessoSei,
        }
      },
      { requestId: "pesquisar" }
    );
    setTimeout(() => {
      setShowSpinner(false);
    }, 1500);
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: FiltrarPesquisa,
  });


  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);
    }

    if (data && requestId === "pesquisar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);
    }

    if (data && requestId === "limpar") {
      setTimeout(() => {
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setPaginatedData(data);
        setShowSpinner(false);
      }, 1000);
    }
  }, [data, requestId]);


  const processo_copiado = () => {
    toast.info('SEI copiado!!', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  };

  const formatarData = (valor) => {
    const dataFormatada = moment(valor).format('DD/MM/YYYY');
    return dataFormatada
  }

  const getStatusClassAndamento = (id) => {
    switch (id) {
      case 1:
        return "btn-andamento btn-geral";
      case 2:
        return "btn-encerrado btn-geral";
      case 3:
        return "btn-suspenso btn-geral";
      case 4:
        return "btn-analise btn-geral";
      default:
        return "";
    }
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    const newOffset = PageOffset + 10;
    setshowSpinnerhandleLoadMore(true);
    if (requestId === "pesquisar") {
      fetch(`${appConfig.host}/api/termosacordos/pesquisar?limit=${itemsToShow}&offset=${newOffset}&idmodalidade=${formik.values.IdModalidade}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    } else {
      fetch(`${appConfig.host}/api/termosacordos/listar?limit=${itemsToShow}&offset=${newOffset}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    }

  };

  const limparCampos = () => {
    setShowSpinner(true);
    setPageOffset(0)
    formik.resetForm()
    request(apiRoutes.main.termosAcordos.listarTermosAcordosPublico,
      {
        routeParams: {
          limit: itemsToShow,
          offset: PageOffset
        }
      },
      { requestId: "carregar" }
    )
  };


  if (errors) {
    return (
      <Row className="mt-4">
        <Col>
          <Alert variant="danger">
            Desculpe, ocorreu um erro durante a pesquisa. Tente novamente mais tarde.
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Container>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column">
          <Row className="bg-light rounded p-3 mt-4">
             <Col lg="8" md="12" sm="12" xs="12">
                <TextInput
                  formik={formik}
                  field="ProcessoSei"
                  label="Pesquisar termo ou acordo pelo processo SEI:"
                />
              </Col>
              <Col className="d-flex flex-row mt-3 justify-content-center">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                  <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                    Pesquisar
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                  <Button variant="link" className="ms-3" onClick={limparCampos}>
                    Limpar
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
            </Col>
          </Row>

        {!loading && paginatedData && !showSpinner && paginatedData.length === 0 && (
          <Alert className="mt-2" variant="primary">Nenhum registro encontrado</Alert>
        )}
        {showSpinner && (
          <Row>
            <Col className="d-flex flex-row mt-5 justify-content-center">
              <h5 className="mb-2">
                <Spinner animation="border" size="lg" variant="warning" />
              </h5>
            </Col>
          </Row>
        )}

        {!showSpinner && paginatedData && paginatedData.map((item) => (
          <Row key={item.id_termos_acordos} className="mt-5 d-flex">
            <Col>
              <Card className="card-border mt-2">
                <Card.Img variant="top" />
                <Card.Body>
                  <Row className="justify-content-between">
                    <Col md="12" lg="8">
                      <Card.Title className="h4">{item.tx_razao_social_entidade + "  -  " + item.modalidade} </Card.Title>
                      <Card.Text className="mt-3 mb-3">{item.tx_descricao}</Card.Text>
                    </Col>
                    <Col
                      lg = "3"
                      md="12"
                      sm="6"
                      xs="12"
                      className=" d-flex flex-column align-items-center"
                    >
                      <Col className="d-flex flex-column align-items-center justify-content-start">
                        <Card.Subtitle>Vigência</Card.Subtitle>
                        <Card.Text className="description mb-1 text-center">
                          Inicio: {formatarData(item.tm_inicio_vigencia)}
                        </Card.Text>
                        <Card.Text className="description text-center ">
                          Fim: {formatarData(item.tm_fim_vigencia)}
                        </Card.Text>
                        <Card.Subtitle>processo SEI: </Card.Subtitle>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Copiar Nº do processo</Tooltip>}>

                          <Card.Text
                            onClick={() => {
                              navigator.clipboard.writeText(item.n_processo);
                              processo_copiado();
                            }}
                          >
                            <span style={{ cursor: "pointer" }}>{FormatarSEI(item.n_processo)}<AiOutlineCopy /></span>

                            {item.processo}
                          </Card.Text>
                        </OverlayTrigger>
                        <Card.Text className="mt-2 d-flex mb-3">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip className="medium">{"Status da parceria" + " " + item.tx_nome}</Tooltip>}
                          >
                            <span className={`${getStatusClassAndamento(item.id_status_andamento_termos_acordos)}`}>
                              {item.tx_nome + " "}
                              {item.id_status_andamento_termos_acordos !== 1 && item.id_status_andamento_termos_acordos !== 2 ? (
                                <AiOutlineExclamationCircle />
                              ) : (
                                ""
                              )}
                            </span>
                          </OverlayTrigger>
                        </Card.Text>
                      </Col>
                    </Col>
                    <Card.Text>
                      <Button variant="link" onClick={(e) => {
                        e.preventDefault()
                        history.push(`/listar/arquivos-andamentos/${tipo}/${item.id_termos_acordos}`)
                      }}>
                        Visualizar arquivos e andamentos.
                      </Button>
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
        <Row>
          {showSpinnerhandleLoadMore && (
            <Row>
              <Col className="d-flex flex-row mt-2 justify-content-center">
                <h5 className="mb-2">
                  <Spinner animation="border" size="lg" variant="warning" />
                </h5>
              </Col>
            </Row>
          )}
          {paginatedData && !showSpinner && !showSpinnerhandleLoadMore && paginatedData.length < TotalItens && (
            <div className="d-flex justify-content-center">
              <Button onClick={handleLoadMore}>Carregar mais</Button>
            </div>
          )}
        </Row>
      </Container>
    </>
  );
};

export default TermosEAcordosLista;
