import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";
import SelectFileInputGeneral from "../../../components/UI/SelectFileInput/SelectFileInputGeneral";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import Inputmask from "inputmask";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";

const validationSchema = yup.object().shape({
  Titulo: yup.string().max(80, 'O título não pode ter mais de 80 caracteres').required("Informe um título para a proposta "),
  Descricao: yup.string().max(500, 'Informe uma descrição resumida com até 500 caracteres').required("Informe uma descrição para a proposta "),
  IdArea: yup.string().required("Selecione uma área para a proposta "),
  IdSecretaria: yup.string().required("Selecione qual orgão deve receber sua proposta "),
  InteresseSocial: yup.string().max(500, 'Informe o interesse social de maneira resumida, com no máximo 500 caracteres.').required("Descreva o interesse social na proposta "),
  Beneficios: yup.string().max(500, 'Informe os benefícios de maneira resumida, com no máximo 500 caracteres.').required("Descreva os benefícios sociais trazidos pela implementação da proposta "),
  Diagnostico: yup.string().max(500, 'Informe o diagnóstico de maneira resumida, com no máximo 500 caracteres.').required("Descreva a realidade que a implementação da proposta irá modificar "),
  Viabilidade: yup.string().max(500, 'Informe a viabilidade de maneira resumida, com no máximo 500 caracteres.').required("Descreva aspectos que favorecem a viabilidade da proposta"),
  EstimativaCusto: yup.string().required("Descreva a estimativa de custo para execução da proposta"),
  EstimativaMesesPrazo: yup.string().required("Descreva a estimativa de prazo para execução da proposta"),
  Area: yup.string().when("IdArea", {
    is: "16",
    then: yup.string().required("Informe a área social relativa à proposta"),
    otherwise: yup.string().nullable().strip().max(60,'O nome da área deve ter no máximo 60 caracteres.'),
  }),
  IdSetor: yup.string().when('IdSecretaria', {
    is: (val) => !!val,
    then: yup.string().required("Selecione qual setor deve receber sua proposta"),
    otherwise: yup.string().nullable().strip(),
  }),
});

const initialValues = {
  IdSetor: '',
  IdSecretaria: "",
  IdArea: "",
  Area: "",
  Titulo: "",
  Descricao: "",
  InteresseSocial: "",
  Diagnostico: "",
  Viabilidade: "",
  Beneficios: "",
  EstimativaMesesPrazo: "",
  EstimativaCusto: "",
  Arquivo: null,
  ArquivoNome: null,
};



const PropostaDetalhe = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const { request, data, errors, loading} = useApiClient();



  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const handleSubmitCleanMask = (values) => {
    const EstimativaValorSemMascara = values.EstimativaCusto.replace(/[^\d]+/g, "");
    const EstimativaMesesPrazoSemMascara = values.EstimativaMesesPrazo.replace(/[^\d]+/g, "");
    values.EstimativaMesesPrazo = EstimativaMesesPrazoSemMascara;
    values.EstimativaCusto = EstimativaValorSemMascara;
    request(apiRoutes.main.propostas.salvar, values, {
      requestId: "salvar",
    });
  };


  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitCleanMask,
  });

  const { setSubmitting, isSubmitting } = formik;
  useEffect(() => {
    if (data && !errors) {
      toast.success("Proposta Lançada com sucesso!!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });
    }

    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });

    }
  }, [data, errors, history, setSubmitting]);




  useEffect(() => {

    const QuantidaeDeMessesMask = Inputmask({
      alias: "numeric",
      prefix: " ",
      suffix: " Meses",
      digits: 0,
      radixPoint: " ",
      numericInput: true,
      allowMinus: false,
    });

    const valorMonetarioMask = Inputmask({
      alias: "numeric",
      prefix: "R$ ",
      radixPoint: ",",
      digits: 2,
      rightAlign: false,
      positionCaretOnClick: "none",
      numericInput: true,
      allowMinus: false,
      groupSeparator: ".",
    });

    QuantidaeDeMessesMask.mask(document.getElementById("EstimativaMesesPrazo"));
    valorMonetarioMask.mask(document.getElementById("EstimativaCusto"));
  }, []);


  const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");

  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      Arquivo: uploadPayload.arquivo,
      ArquivoNome: uploadPayload.arquivoNome,
    }));
  };

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">Manifestação de interesse Social</h1>
          </Col>
        </Row>
      </Container>
      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
            <Row>
              <div className="alert alert-info mt-5" role="alert">
                    <h4 className="alert-heading">Instruções</h4>
                    <p> O Procedimento de Manifestação de Interesse Social (PMIS) é o instrumento pelo qual as organizações da sociedade civil, movimentos sociais e cidadãos poderão apresentar propostas à Administração Pública, para que esta avalie a possibilidade de realização de um chamamento público com o objetivo de eventualmente celebrar parceria. </p>
                    <p> Ao registrar uma Manifestação de Interesse Social, é necessário selecionar uma secretaria e, em seguida, escolher um setor. O setor será responsável por receber sua proposta e dar os devidos encaminhamentos.</p>
                    <p> Para submeter uma Manifestação de Interesse Social, não é necessária a anexação de arquivos à proposta. No entanto, você pode anexar um arquivo em formato PDF para fundamentar melhor sua proposta, com o objetivo de reforçar a argumentação e convencer a Administração da importância de sua implementação. </p>
              </div>
            </Row>
            <Form onSubmit={formik.handleSubmit}>

              <Row className="d-flex g-4">
                <Col md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados da proposta</h3>
                </Col>
                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <TextInput formik={formik} field="Titulo" label="Título da Proposta" placeholder="Informe o titulo" disabled={isSubmitting} />
                </Col>
                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="Descricao"
                    label="Descrição Resumida da Proposta"
                    placeholder="Descreva aqui"
                    rows={4}
                    disabled={isSubmitting}

                  />
                </Col>

                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="IdArea"
                    label="Área de Atuação"
                    defaultText="Selecione uma área de atuação"
                    cacheKey="areas"
                    disabled={isSubmitting}
                  />
                </Col>
                {formik.values.IdArea === "16" && (
                  <Col lg="12" md="12" sm="12" xs="12">
                    <TextInput
                      formik={formik}
                      field="Area"
                      label="Especifique a Área de Atuação:"
                      placeholder="Informe a área de atuação"
                      readOnly={readOnly}
                      disabled={isSubmitting}
                    />
                  </Col>
                )}


                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="IdSecretaria"
                    label="Selecione a Secretaria ou Autarquia que irá receber a proposta."
                    cacheKey="orgaosProposta"
                    defaultText="Selecione a Secretaria ou Autarquia"
                    disabled={isSubmitting}
                    allowEmpty
                  />
                </Col>
                {formik.values.IdSecretaria && (
                  <Col className="mt-4" lg="12" md="12" sm="12" xs="12">
                    <SelectInputCached
                      formik={formik}
                      field="IdSetor"
                      label="Selecione o setor que irá receber sua proposta."
                      defaultText="Selecione o setor"
                      cacheKey="setoresPorSecretaria"
                      setoresPorSecretaria={true}
                      idSecretaria={formik.values.IdSecretaria ? formik.values.IdSecretaria : 0}
                      disabled={isSubmitting}
                      allowEmpty
                    />
                  </Col>)}


                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="InteresseSocial"
                    label="Indicação do interesse público envolvido"
                    placeholder="Descreva aqui"
                    rows={4}
                    disabled={isSubmitting}
                  />
                </Col>

                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="Diagnostico"
                    label="Diagnóstico da realidade que se quer modificar, aprimorar
                      ou desenvolver"
                    placeholder="Descreva aqui"
                    rows={4}
                    disabled={isSubmitting}
                  />
                </Col>

                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="Viabilidade"
                    label="Indicação da viablidade"
                    placeholder="Descreva aqui"
                    rows={4}
                    disabled={isSubmitting}
                  />
                </Col>

                <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                  <TextareaInput
                    formik={formik}
                    field="Beneficios"
                    label="Indicação dos beneficios"
                    placeholder="Descreva aqui"
                    rows={4}
                    disabled={isSubmitting}
                  />
                </Col>

                <Col className="mt-5" lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="EstimativaCusto"
                    placeholder="R$ 0,00"
                    label="Estimativa de custo:"
                    disabled={isSubmitting}
                  />
                </Col>

                <Col className="mt-5" lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="EstimativaMesesPrazo"
                    mask={{
                      alias: "numeric",
                      suffix: " Meses",
                      allowMinus: false,
                    }}
                    label="Estimativa do Prazo de entrega:"
                    placeholder="Informe a quandidade de messes nesseários para concluir seu projeto."
                    disabled={isSubmitting}
                  />
                </Col>

                <Row>
                  <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                    <h3 className="my-3">Arquivos Opcionais</h3>
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <p>
                      Nesta seção, você tem a opção de anexar um arquivo adicional para fundamentar sua proposta de forma mais detalhada. A inclusão de arquivos é facultativa, mas pode enriquecer a compreensão da sua proposta. Para anexar um arquivo clique em "Selecionar Arquivo".
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-5" lg="12" md="12" sm="12" xs="12">
                    <SelectFileInputGeneral
                      show={!readOnly}
                      showListfile={!readOnly}
                      field="Arquivo"
                      handleArquivo={handleArquivo}
                      disabled={isSubmitting}
                    />
                  </Col>
                </Row>

              </Row>
              {/**  {formik.values.tx_tipo === "osc" && <PropostaDetalheArquivos id={id || formik.values.id_proposta} readOnly={readOnly} />}*/}
              {!!formik.status && (
                <Row>
                  <Col md="12" sm="12" xs="12">
                    <Alert variant="danger">{formik.status}</Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  {/** 
                  <Button className="mt-3" onClick={() => history.goBack()}>
                    Voltar
                  </Button>

                  &nbsp;&nbsp;
                  {!readOnly && (
                    <Button
                      onClick={() => {
                        
                        formik.handleSubmit();
                      }}
                      className="mt-3 ms-3"
                    >
                      <SpinnerLabel loading={formik.isSubmitting} label="Salvar Rascunho" />
                    </Button>
                  )}
                  
                  &nbsp;&nbsp;
                  {!readOnly && (id || formik.values.id_proposta) && (
                    <Button
                      className="mt-3 ms-3"
                      onClick={() => {
                        
                        formik.setSubmitting(true);
                        request(
                          apiRoutes.main.propostas.submeter,
                          { id: id || formik.values.id_proposta },
                          { requestId: "submeter" }
                        );
                      }}
                    >
                      Submeter Proposta
                    </Button>
                  )}*/}

                  <Row>
                    <Col className="d-flex justify-content-center mt-5">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip className="medium mt-1">Enviar proposta</Tooltip>}>
                        <Button
                          className="mt-3"
                          disabled={isSubmitting}
                          onClick={() => formik.handleSubmit()}
                        >
                          <SpinnerLabel loading={formik.isSubmitting} label="Salvar" />
                        </Button>
                      </OverlayTrigger>

                      &nbsp;&nbsp;

                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                        <Button className="mt-3" disabled={isSubmitting}  onClick={() => history.goBack()}>
                          Voltar
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PropostaDetalhe;


