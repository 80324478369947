import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Accordion,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SelectInputCached from "../../components/UI/Formik/SelectInput/SelectInput.cached";
import { AppContext } from "../../context/AppContext";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { FormatDate } from "../../components/UI/Format/Format";
import { useHistory } from "react-router";
import { TbMailForward } from "react-icons/tb";
import appConfig from "../../config/app.config";
import { TOKEN_KEY } from "../../redux/auth";


const initialValues = {
  IdStatusAndamento: 0,
};

const ListagemDeImpugnacoes = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSpinnerhandleLoadMore, setshowSpinnerhandleLoadMore] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(10);
  const [PageOffset, setPageOffset] = useState(0);
  const [TotalItens, setTotalItens] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const history = useHistory();


  const storedToken = localStorage.getItem(TOKEN_KEY);

  const authHeaders = {};
  if (storedToken) {
    authHeaders.Authorization = `Bearer ${storedToken}`;
  }

  useEffect(() => {
    setBackgroundClassList(["background-2"]);
  });

  useEffect(() => {
    request(apiRoutes.main.impugnacao.secretariaListarImpugnacao,
      {
        routeParams: {
          limit: itemsToShow,
          offset: PageOffset
        }
      },
      { requestId: "carregar" }
    );
  }, [request]);

  useEffect(() => {
    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);
    }

    if (data && requestId === "pesquisar") {
      setTimeout(() => {
        setPaginatedData(data);
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setShowSpinner(false);
      }, 1000);
    }

    if (data && requestId === "limpar") {
      setTimeout(() => {
        setTotalItens(data.length > 0 ? data[0].totalitens : [])
        setPaginatedData(data);
        setShowSpinner(false);
      }, 1000);
    }
  }, [data, requestId]);

  const FiltrarPesquisa = (values) => {
    setShowSpinner(true);
    setPageOffset(0)
    request(apiRoutes.main.impugnacao.filtrarImpugnacao,
      {
        routeParams: {
          limit: itemsToShow,
          offset: 0,
          idstatus: formik.values.IdStatusAndamento,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: FiltrarPesquisa,
  });

  const limparCampos = () => {
    setShowSpinner(true);
    setPageOffset(0)
    formik.resetForm();
    request(apiRoutes.main.impugnacao.secretariaListarImpugnacao,
      {
        routeParams: {
          limit: itemsToShow,
          offset: 0
        }
      },
      { requestId: "limpar" }
    )
  };

  const onButtonClick = (item) => {
    fetch(
      `${appConfig.host}/api/impugnacao/baixar/${item.id_impugnacao_arquivo}`
    )
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = item.nm_arquivo;
        a.click();
      });
  };

  const getStatusClass = (id) => {
    switch (id) {
      case 1:
        return "bnt-ativo rounded-pill btn-round bnt-status";
      case 2:
        return "bnt-encerrado rounded-pill btn-round bnt-status";
      case 3:
        return "bnt-pendende rounded-pill btn-round bnt-status";
      default:
        return "";
    }
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    const newOffset = PageOffset + 1;
    setshowSpinnerhandleLoadMore(true);
    if (requestId === "pesquisar") {
      fetch(`${appConfig.host}/api/impugnacao/secretaria/filtrar/impugnacao?limit=${itemsToShow}&offset=${newOffset}&idstatus=${formik.values.IdStatusAndamento}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    } else {
      fetch(`${appConfig.host}/api/impugnacao/secretaria/listar?limit=${itemsToShow}&offset=${newOffset}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar dados.');
          }
          return response.json();
        })
        .then(data => {
          setPageOffset(newOffset);
          setTimeout(() => {
            setshowSpinnerhandleLoadMore(false);
            setPaginatedData(prevData => [...prevData, ...data]);
          }, 900);
        })
        .catch(error => {
          console.error('Erro na requisição:', error);
          setshowSpinnerhandleLoadMore(false);
        });
    }
  };

  return (
    <>
      <Container id="cabecalho-pagina">
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow-orange mt-5">
              Dispensa ou inexigibildiade de chamamento público
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="d-flex flex-column">
            <Row>
              <Col>
                <Accordion className="my-5">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="0"
                        className="w-100 text-left"
                      >
                        O que é dispensa ou inexigibildiade de chamamento público?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="text-justify mt-3">
                          A dispensa do chamamento público ocorre nas seguintes situações:
                          <ul className="ms-2">
                            <li>(I) no caso de urgência decorrente de paralisação ou iminência de paralisação das atividades de relevante interesse público;</li>
                            <li>(II) nos casos de guerra, calamidade pública, grave perturbação da ordem pública ou ameaça à paz social;</li>
                            <li>(III) nos casos de programas de proteção a pessoas ameaçadas ou em situação que possa comprometer sua segurança; e</li>
                            <li>(IV) no caso de atividades voltadas ou vinculadas a serviços de saúde, educação e assistência social, desde que a OSC da parceria esteja previamente credenciada pelo órgão gestor.</li>
                          </ul>

                          <br />

                          A inexigibilidade de chamamento público ocorre nas seguintes situações: <br />
                          <ul className="ms-2">
                            <li>(I) objeto da parceria é singular; e</li>
                            <li>(II) quando as metas só puderem ser atingidas por uma OSC específica, especialmente quando a OSC beneficiada estiver identificada em acordo internacional ou em lei (inclusive subvenção social).</li>
                          </ul>
                          <br />
                          Para os casos de dispensa e inexigibilidade de chamamento público, a ausência da sua realização deve ser justificada. Tal explicação pode ser questionada por qualquer OSC ou interessado
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="1"
                        className="w-100 text-left"
                      >
                        O que é uma impugnação?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p className="text-justify mt-3">
                          Ao lançar um chamamento público dispensado ou inexigível, qualquer cidadão tem o direito de apresentar uma impugnação, caso identifique irregularidades ou questione os termos do processo. O servidor encarregado do procedimento tem o prazo de cinco dias úteis para analisar e responder de maneira fundamentada à impugnação. Esse procedimento é essencial para garantir a transparência e a legalidade nas contratações públicas, promovendo a integridade e a credibilidade do processo de seleção.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>

              <Row>
                <Col>
                  <h2 className="mt-5">Impugnações</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Aqui são listadas todas Impugnações enviadas para sua secretaria. Para responder basta clicar no icone de carta.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="bg-light rounded p-3">
                  <Form>
                    <Row>

                      <Col lg="9" md="9" sm="12" xs="12">
                        <SelectInputCached
                          formik={formik}
                          field="IdStatusAndamento"
                          label="Andamento da impugnação"
                          cacheKey="impugnacao"
                          allowEmpty
                        />
                      </Col>
                      <Col className="d-flex flex-row mt-3 justify-content-center">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                          <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                            Pesquisar
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                          <Button variant="link" className="ms-3" onClick={limparCampos}>
                            Limpar
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        {showSpinner && (
          <Row>
            <Col className="d-flex flex-row mt-5 justify-content-center">
              <h5 className="mb-2">
                <Spinner animation="border" size="lg" variant="warning" />
              </h5>
            </Col>
          </Row>
        )}

        {data && data.length === 0 && !showSpinner && (
          <Alert className="mt-2" variant="primary">Nenhum registro encontrado</Alert>
        )}
        {data && !showSpinner &&
          paginatedData.map((item) => (

            <Row className="mt-4" key={item.id_impugnacao}>
              <Col>
                <Card className="card-border mt-5">
                  <Card.Img variant="top" />
                  <Card.Body>
                    <Row className="justify-content-between">
                      <Col lg="9" md="9" sm="6">
                        <Card.Title className="h4">{item.tx_titulo} </Card.Title>
                        <Card.Text className="mt-3 mb-3" >{item.tx_descricao}</Card.Text>
                      </Col>
                      <Col
                        lg="3"
                        md="3"
                        sm="6"
                        className=" d-flex flex-column align-items-center "
                      >
                        <Col className="d-flex flex-column align-items-center justify-content-start">
                          <Card.Subtitle> Data do lançamento:</Card.Subtitle>
                          <Card.Text className="description mb-1 text-center">
                            {FormatDate(item.tm_inclusao, "dd/MM/yyyy")}
                          </Card.Text>
                          <Card.Subtitle> Data limite para resposta:</Card.Subtitle>
                          <Card.Text className="description mb-1 text-center">
                            {FormatDate(item.tm_fim_data_resposta_impugnacao, "dd/MM/yyyy")}
                          </Card.Text>
                          <Card.Text className="mt-2 d-flex mb-3">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip className="medium mt-1">{"Status da parceria" + " " + item.status}</Tooltip>}
                            >
                              <span className={`${getStatusClass(item.id_status_andamento_impugnacao)}`}>
                                {item.status + " "}

                              </span>
                            </OverlayTrigger>

                            {item.id_status_andamento_impugnacao === 1 ? (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip className="medium mt-1">{"Responder Impugnação"}</Tooltip>}
                              >
                                <span className={`${getStatusClass(item.id_status_andamento_impugnacao)} ms-1`}>

                                  <TbMailForward
                                    onClick={() => history.push(`/responder/impugnacao/${item.id_impugnacao}`)}
                                    color="white"
                                    size={20}
                                    cursor={"pointer"}
                                  /></span>
                              </OverlayTrigger>
                            ) : (
                              ""
                            )}
                          </Card.Text>
                        </Col>
                      </Col>
                      {item.id_impugnacao_arquivo && item.nm_arquivo && (
                        <Card.Text>
                          <Col lg="11" md="11" sm="11" xs="11">
                            <Button variant="link" onClick={() => onButtonClick(item)}>
                              Baixar Fundamentação
                            </Button>
                          </Col>
                        </Card.Text>)}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

          ))}
        {showSpinnerhandleLoadMore && (
          <Row>
            <Col className="d-flex flex-row mt-2 justify-content-center">
              <h5 className="mb-2">
                <Spinner animation="border" size="lg" variant="warning" />
              </h5>
            </Col>
          </Row>
        )}
        {data && TotalItens > paginatedData.length && !showSpinner && !showSpinnerhandleLoadMore && (
          <div className="d-flex justify-content-center">
            <Button onClick={handleLoadMore}>Carregar mais</Button>
          </div>
        )}
      </Container>

    </>
  );
};
export default ListagemDeImpugnacoes;
