import React, {useEffect, useContext, useRef} from "react";
import {Container, Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {AppContext} from "../../context/AppContext";
import { useHistory, useParams } from "react-router";
import * as yup from "yup";
import {useFormik} from "formik";
import TextInput from "../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../components/UI/SpinnerLabel";
import useApiClient, {apiRoutes} from "../../hooks/apiClient";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer, Bounce} from "react-toastify";

const asciiEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail invalido.")
    .required("Informe o e-mail do usuário")
    .matches(asciiEmailRegex, 'O e-mail não deve conter caracteres especiais, como símbolos, acentos (á, ç, ü, etc.), letras fora do padrão inglês e espaço em branco.'),
});

const initialValues = {
    id:"",
    email: "",
};

const PaginaDeReenvioDeLinkCadastral = () => {
  const {setBackgroundClassList} = useContext(AppContext);
  const history = useHistory();
  const { id } = useParams();

  const {request,requestId, data, errors, loading} = useApiClient();


  useEffect(() =>{
    if(id){
      initialValues.id = id
      request(
        apiRoutes.main.UserGov.CarregarUserGovPorId,
        {routeParams: {id}},
        { requestId: "carregar" }
      );
    }

  }, [id, request])


  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.UserGov.ReenviarLinkAtivacaoUserGov, values, {
        requestId: "salvar",
      });
    } 
  });

  const {setSubmitting, isSubmitting, setValues} = formik;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
     if (data && requestId === "carregar"){
      formik.setValues(() => ({
        email: data.value.email,
        idusuariodados: id
      }));
     }
     

    if (data && requestId === "salvar") {
      toast.success("Link de ativação cadastral enviado com sucesso! O usuário deverá acessar o link enviado para o seu e-mail, a fim de definir sua senha e concluir a ativação do cadastro. O link permanecerá ativo por 12 horas.", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload();
        },
      });
    }

    if (errors) {
      setSubmitting(false);
      toast.error(
        errors.message ||
          "Desculpe algo deu errado, Tente novamente mais tarde!",
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
          onClose: () => {
    
          },
        }
      );
    }
  }, [data, errors, setSubmitting, history, requestId]);


  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Ativação cadastral
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <Col className="mt-5">
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Reenvio de link de ativação cadastral</h3>
                </Col>
                <Row>
                    <Col>
                        <p>Para reenviar o link de ativação cadastral do usuário selecionado, primeiro certifique-se de que o endereço de e-mail informado está correto. Caso identifique que o e-mail está incorreto, realize a correção necessária antes de prosseguir. Se o e-mail estiver correto, basta clicar na opção "Reenviar Link" para concluir o processo. Lembre-se de que o link é válido por apenas 12 horas. Após esse período, ele será considerado inválido, e será necessário reenviá-lo.</p>
                    </Col>
                </Row>

                <Col lg="10" md="10" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="email"
                    label="E-mail do usuário (Verifique se o e-mail está correto):"
                    disabled = {isSubmitting}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Reenviar Link de Ativação Cadastral para o e-mail selecionado</Tooltip>}>
                    <Button
                      className="mt-3"
                      disabled = {isSubmitting}
                      onClick={() => formik.handleSubmit()}
                    >
                      <SpinnerLabel loading={formik.isSubmitting} label="Reenviar link" />
                    </Button>
                  </OverlayTrigger>
                  &nbsp;&nbsp;
                  <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                    <Button className="mt-3" disabled={isSubmitting}  onClick={() => history.goBack()}>
                      Voltar
                    </Button>
                </OverlayTrigger>
                </Col>
              </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaginaDeReenvioDeLinkCadastral;
