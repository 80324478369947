import { useFormik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { Col, Container, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as yup from "yup";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import { AppContext } from "../../../context/AppContext";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { useHistory } from "react-router";
import SelectFileInputGeneral from "../../../components/UI/SelectFileInput/SelectFileInputGeneral";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Inputmask from "inputmask";
import TextareaInput from "../../../components/UI/Formik/TextareaInput/TextareaInput";

const validationSchema = yup.object().shape({
  Titulo: yup.string().max(100, 'O título não pode ter mais de 100 caracteres').required("Informe o titulo do chamamento."),
  TipoChamamentoPublico: yup.string().required("Selecione o tipo do chamamento."),
  Area: yup.string().required("Selecione a área do chamamento."),
  IdStatus: yup.string().required("Selecione o status do chamamento."),
  Objeto: yup.string().max(400, 'O objeto da parceria não pode ter mais que 400 caracteres').required("Informe uma descrição resumida do objeto."),
  Area: yup.string().required("Informe uma área de acordo com o chamamento."),
  Nprocesso: yup.string().transform((value) => value.replace(/[^0-9]/g, '')) 
  .required("Informe o Número do processo SEI")
  .length(20, "O número do processo SEI deve ter exatamente 20 caracteres"),
});

const initialValues = {
  Titulo: "",
  TipoChamamentoPublico: "",
  Nprocesso: "",
  Objeto: "",
  Area: "",
  Edital: "",
  EditalNome: "",
  IdStatus: "",
  Justificativa: "",
};

const LançamentoChamamentoPublico = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const { request, data, errors, loading } = useApiClient();
  const history = useHistory();



  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  const handleSubmitClean = (values) => {
    const SeiSemMask = values.Nprocesso.replace(/[^\d]+/g, "");
    values.Nprocesso = SeiSemMask;
    request(apiRoutes.main.chamamentoPublico.uploadChamamentoPublico, values, {
      requestId: "salvar",
    });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitClean,
  });


  const handleArquivo = (uploadPayload) => {
    formik.setValues((curr) => ({
      ...curr,
      Edital: uploadPayload.arquivo,
      EditalNome: uploadPayload.arquivoNome,
    }));
  };

  const { setSubmitting, resetForm, isSubmitting } = formik;

  useEffect(() => {
    if (data) {
      toast.success("Chamamento lançado com Sucesso!!!", {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
          window.location.reload();
        }
      });

    }


    if (errors) {
      toast.error(errors.message || "algo deu errado!!", {
        position: "top-center",
        autoClose: 5500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setSubmitting(false);
        }
      });

    }
  }, [data, errors, history, setSubmitting]);


  useEffect(() => {

    const SEIMask = Inputmask({
      mask: "99999999.999999/9999-99",
      placeholder: " ",
      numericInput: true,
      rightAlign: false,
      numericInput: true,
      allowMinus: false,
      positionCaretOnClick: "none",
    });

    SEIMask.mask(document.getElementById("Nprocesso"));

  }, []);

  const readOnly = loading || !!((data || {}).fg_somente_leitura === "S");



  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Lançamento dos chamentos públicos
            </h1>
          </Col>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
        <Row>
          <div className="alert alert-info mt-5" role="alert">
                <h4 className="alert-heading">Instruções</h4>
                <p> O chamamento público é um procedimento adotado pela administração pública para a execução de atividades ou projetos de interesse público. Essa parceria é formalizada por meio de termos de colaboração, fomento ou acordos de cooperação. </p>
                <p> Os chamamentos públicos podem ser classificados em duas categorias: os chamamentos comums, que aceitam propostas do público, e os dispensados ou inexigíveis, que foram dispensados pela administração e podem ser objeto de impugnações.</p>
                <p> Ao lançar um chamamento público dispensado ou inexigível, o sistema definirá automaticamente a data de término das impugnações para 5 dias após o lançamento. Durante essa etapa, não será possível modificar o andamento do chamamento.</p>
                <p> Lembre-se de anexar o edital ou a justificativa de dispensa ao chamamento público. Se desejar incluir mais arquivos, você deve acessar a listagem de chamamentos públicos e selecionar a opção para anexar arquivos.</p>
          </div>
        </Row>
        <Row className="d-flex g-4">
          <Col md="12" sm="12" xs="12">
            <h2 className="my-3">Dados do chamamento</h2>

            <Col lg="12" md="12" sm="12" xs="12">
              <SelectInput
                formik={formik}
                field="TipoChamamentoPublico"
                label="Tipo:"
                disabled={isSubmitting}
              >
                <option>Selecione o tipo de chamamento</option>
                <option value={"L"}>Chamamento Comum</option>
                <option value={"D"}>Dispensa ou inexigibilidade</option>
              </SelectInput>
            </Col>
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="Titulo"
              label="Titulo:"
              placeholder="Adicione um título ao seu chamamento"
              disabled={isSubmitting}
            />
          </Col>
          <Col lg="12" md="12" sm="12" xs="12">
            <TextInput
              formik={formik}
              field="Nprocesso"
              label="Nº processo SEI:"
              placeholder="Adicione o Nº do processo SEI"
              disabled={isSubmitting}
            />
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <TextareaInput
              formik={formik}
              field="Objeto"
              label="Objeto da parceria:"
              placeholder="Adicione o objeto da parceria"
              rows={5}
              disabled={isSubmitting}
            />
          </Col>

          <Col md="12" sm="12" xs="12">
            <SelectInputCached
              formik={formik}
              field="Area"
              label="Área de Atuação:"
              cacheKey="areas"
              disabled={isSubmitting}
              allowEmpty
            />
          </Col>

          <Col lg="12" md="12" sm="12" xs="12">
            <SelectInputCached
              formik={formik}
              field="IdStatus"
              label="Status do Chamamento:"
              cacheKey="chamamentos"
              disabled={isSubmitting}
              filtraPorTipo={formik.values.TipoChamamentoPublico}
              allowEmpty
            />
          </Col>
          <Row>
            <Col className="mt-3" lg="12" md="12" sm="12" xs="12">
              <h3 className="my-3 mt-3">Adicionar Arquivo ao chamamento público</h3>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <p>
                Nesta seção, você deve anexar um arquivo PDF ao chamamento público, sendo recomendável o edital correspondente. Caso deseje incluir documentos adicionais, salve o chamamento e acesse a opção para atualizar um chamamento público na listagem de chamamentos.
              </p>
            </Col>
            <Col className="mt-2" lg="12" md="12" sm="12" xs="12">
              <SelectFileInputGeneral
                disabled={loading}
                show={!readOnly}
                showListfile={!readOnly}
                field="Edital"
                handleArquivo={handleArquivo}
              />
            </Col>
          </Row>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center mt-5">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Salvar no sistema</Tooltip>}>
              <Button
                className="mt-3"
                disabled={formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
              >
                <SpinnerLabel loading={formik.isSubmitting} label="Salvar" />
              </Button>
            </OverlayTrigger>
            &nbsp;&nbsp;
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
              <Button className="mt-3" disabled={formik.isSubmitting}  onClick={() => history.goBack()}>
                Voltar
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LançamentoChamamentoPublico;
