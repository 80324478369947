import SelectInput from "./SelectInput";
import { Spinner, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import { useSelector, useDispatch } from "react-redux";
import { cacheActions } from "../../../../redux/cache";


const SelectInputCached = (props) => {
  const { formik, field, label, placeholder, readOnly, cacheKey, defaultText, allowEmpty, id, isUpdate, isPropostaAndamento, disabled, filtraPorTipo, categoriaRelatorio, setoresPorSecretaria, idSecretaria, isAndamentoRelatorio } =
    props;
  const cache = useSelector((state) => state.cache);
  const [options, setOptions] = useState([]);
  const [CarregouSetores, setCarregouSetores] = useState(false);
  const [showSpinner, setshowSpinner] = useState(false);
  const { request, data, loading, requestId } = useApiClient();
  const dispatch = useDispatch();



  useEffect(() => {
    if (data || cache[cacheKey].length ) setOptions([...cache[cacheKey]]);
    else if(!CarregouSetores){
      request(apiRoutes.main.cache[cacheKey]);}
  }, [cache, cacheKey, request, requestId]);

  useEffect(() => {
    if(idSecretaria && CarregouSetores ){
      setCarregouSetores(false)}
  }, [idSecretaria]);

  useEffect(() => {
    if(idSecretaria && !CarregouSetores){
      setCarregouSetores(true)
      setshowSpinner(true)
      request(
        apiRoutes.main.cache[cacheKey],
        {routeParams: {idSecretaria}},
        { requestId: "carregarSetores" }
      );
    }
  }, [ idSecretaria, requestId, CarregouSetores, cacheKey, request]);

  useEffect(() => {
    
    if (id && !isUpdate && cache[cacheKey].length > 0) {
      const filteredOptions = cache[cacheKey].filter(item => item.id === id);
      setOptions([...filteredOptions]);
    } else if (isUpdate && cache[cacheKey].length === 0) {
      request(apiRoutes.main.cache[cacheKey]);
    }
  }, [cache, cacheKey, request, id, isUpdate]);
  

  useEffect(() => {
    if (data) {
      setOptions([...cache[cacheKey]]);
      dispatch(cacheActions.updateCache({ cacheKey, items: data.value }));
      setTimeout(() => {
        setshowSpinner(false);
      }, 300);
      
    }
  }, [data, cacheKey, dispatch]);

  if (showSpinner || loading){
    return <Spinner animation="border"  variant="warning" size="sm" />;}

  let optionsEl = [];


  if(id && isUpdate && options.length > 0 && !filtraPorTipo){
    const optionsListFilterUpdate = options.filter(item => item.id !== id);
    const optionsCurrentValue =  options.filter(item => item.id === id);
    optionsEl.push(
      <option key={-1} value={optionsCurrentValue[0].id}>
        {optionsCurrentValue[0].descricao}
      </option>,
      optionsListFilterUpdate.map((item) => (
        <option key={item.id} value={item.id}>
          {item.descricao}
        </option>
      ))
      
    );
  }

   if ((!formik.values[field] || allowEmpty) && !id && !isUpdate)
     optionsEl.push(
       <option key={-1} value="0">
         {defaultText || "Todos"}
       </option>
     );

  const idOption = options.find(item => item.id === id);
  if (idOption && !isUpdate && !filtraPorTipo) {
      optionsEl.push(
          <option value={options[0].id}>
            {options[0].descricao}
          </option>

      );
    }

  if(!id && !isUpdate && !isPropostaAndamento && !filtraPorTipo && !categoriaRelatorio && !setoresPorSecretaria && !isAndamentoRelatorio){
  optionsEl.push(
    options.map((item) => (
      <option key={item.id} value={item.id}>
        {item.descricao}
      </option>
    ))
  )};
  
  if(cacheKey === "chamamentos" && filtraPorTipo === "D"){
    const optionsListFilter = options.filter(item => item.id !== 1 &&  item.id !== 2 &&  item.id !== 3 &&  item.id !== 4 &&  item.id !== 9 &&  item.id !== 10 );
    const optionsListFilterLancar = options.filter(item => item.id == 5 );
    if (id && isUpdate) {
      const optionCurrentValeuUpdate = optionsListFilter.filter(item => item.id === id);
      const optionSemValorCurrent = optionsListFilter.filter(item => item.id !== id && item.id !== 5);
      if(optionCurrentValeuUpdate[0]){
        optionsEl.push(
          <option key={optionCurrentValeuUpdate[0].id} value={optionCurrentValeuUpdate[0].id}>
              {optionCurrentValeuUpdate[0].descricao}
          </option>,
        );
      optionsEl.push(
        optionSemValorCurrent.map((item) => (
            <option key={item.id} value={item.id}>
              {item.descricao}
            </option>
          )))
      }
    }
    else {
      optionsEl.push(
        optionsListFilterLancar.map((item) => (
          <option key={item.id} value={item.id}>
            {item.descricao}
          </option>
        )))
      }
    }

  if (cacheKey === "chamamentos" && filtraPorTipo === "L") {
    const optionsListFilter = options.filter(item => item.id !== 5 && item.id !== 6 && item.id !== 7 && item.id !== 8);
    if (id && isUpdate) {
        const optionCurrentValeuUpdate = optionsListFilter.filter(item => item.id !== id);
        if(optionCurrentValeuUpdate[0]){
          optionsEl.push(
            <option key={optionCurrentValeuUpdate[0].id} value={optionCurrentValeuUpdate[0].id}>
                {optionCurrentValeuUpdate[0].descricao}
            </option>
          );
        }
    }
    optionsEl.push(
        optionsListFilter.map((item) => (
            <option key={item.id} value={item.id}>
                {item.descricao}
            </option>
        ))
    );
  }

  if(isPropostaAndamento){
    const optionsListFilter = options.filter(item => item.id !== 1 &&  item.id !== 2);
    optionsEl.push(
      optionsListFilter.map((item) => (
        <option key={item.id} value={item.id}>
          {item.descricao + " - " + item.texto}
        </option>
      ))
  )}


  if(isAndamentoRelatorio){
    const optionsListFilter = options.filter(item => item.id !== 1);
    optionsEl.push(
      optionsListFilter.map((item) => (
        <option key={item.id} value={item.id}>
          {item.descricao}
        </option>
      ))
  )}

  if(categoriaRelatorio || CarregouSetores ){
    
    optionsEl.push(
      options.map((item) => (
        <option key={item.id} value={item.id}>
          {item.descricao + " - " + item.texto}
        </option>
      ))
  )}

  return (

      <SelectInput
        formik={formik}
        field={field}
        label={label}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
      >
        {optionsEl}
      </SelectInput>
    
  );
};

export default SelectInputCached;
