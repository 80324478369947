import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { FaUserAltSlash } from "react-icons/fa";
import Inputmask from "inputmask";
import ConfirmarExclusaoModal from "../../../components/Modal/ConfirmarExclusaoModal";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TabelPagination from "../../../components/pagination/TabelPaginatio";
import { FormatTelefone } from "../../../components/UI/Format/Format";
import { validateCPF } from "../../../components/UI/Formik/Validations/Validations";
import { FormatDate } from "../../../components/UI/Format/Format";
import { FaUserPen } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";

const validationSchema = yup.object().shape({
  cpf: yup
    .string()
    .transform((originalValue) => {
      const cleanedValue = originalValue.replace(/[^\d]+/g, "");
      return cleanedValue;
    })
    .matches("[0-9]{11}", "Somente números de 0-9 e no máximo 11 dígitos")
    .test("cpf-validation", "CPF inválido.", (cpf) => {
      return validateCPF(cpf);
    })
    .required("Informe seu CPF."),
});

const initialValues = {
  cpf: "",
};

const SalvarPesquisa = {
  cpf: "",
};

const ListagemServidores = () => {
  const { request, data, errors, requestId } = useApiClient();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [limparPesquisa, setlimparPesquisa] = useState(true);
  const [offsetPesquisa, setoffsetPesquisa] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const itemsPerPage = 5;


  useEffect(() => {
    request(apiRoutes.main.servidor.listarServidor,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offset
        }
      },
      { requestId: "carregar" }
    );
  }, [request, offset]);

  useEffect(() => {
    if (!limparPesquisa) {
      request(apiRoutes.main.servidor.pesquisarServidor,
        {
          routeParams: {
            limit: itemsPerPage,
            offset: offsetPesquisa,
            Cpf: SalvarPesquisa.cpf,
          }
        },
        { requestId: "pesquisar" }
      );
    }
  }, [offsetPesquisa]);


  const FiltrarPesquisa = (values) => {
    SalvarPesquisa.cpf = values.cpf.replace(/[^\d]+/g, "");
    setlimparPesquisa(false);
    request(apiRoutes.main.servidor.pesquisarServidor,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: offsetPesquisa,
          cpf: SalvarPesquisa.cpf,
        }
      },
      { requestId: "pesquisar" }
    );
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: FiltrarPesquisa,
  });


  const limparCampos = () => {
    setShowSpinner(true)
    formik.resetForm();
    SalvarPesquisa.cpf = "";
    setlimparPesquisa(true)
    setoffsetPesquisa(0)
    setoffset(0)
    request(apiRoutes.main.servidor.listarServidor,
      {
        routeParams: {
          limit: itemsPerPage,
          offset: 0
        }
      },
      { requestId: "carregar" }
    );
  };

  useEffect(() => {

    if (data && requestId === "carregar") {
      setTimeout(() => {
        setPaginatedData(data);
        settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
        setShowSpinner(false);
      }, 700);
    }

    if (data && requestId === "pesquisar") {
      setShowSpinner(true);
      setPaginatedData(data);
      settotalPages(data && data.length > 0 ? Math.ceil(data[0].totalitens / itemsPerPage) : 0);
      setTimeout(() => {
        setShowSpinner(false);
      }, 900);
    }

    if (data && requestId === "remover") {
      toast.success("Servidor desativado com sucesso!!!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload();
        },
      });
    }

    if (errors) {
      toast.error(errors.message || "Desculpe, algo deu errado. Tente novamente mais tarde.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          setShowSpinner(false);
          window.location.reload();
        },
      });
    }
  }, [data, requestId])


  const handlePageChange = (pageNumber) => {
    setShowSpinner(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage)
    if (!limparPesquisa) {
      setoffsetPesquisa(newOffset);
    } else {
      setoffset(newOffset);
    }
  };

  const handleDeleteConfirm = () => {
    if (userIdToDelete) {
      request(
        apiRoutes.main.servidor.removerServidor,
        {
          routeParams: { id: userIdToDelete },
        },
        { requestId: "remover" }
      );
    }
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setShowSpinner(false);
  };

  useEffect(() => {
    const CPFFormat = Inputmask({
      mask: "999.999.999-99",
      numericInput: true,
    });


    CPFFormat.mask(document.getElementById("cpf"));
  }, []);

  return (
    <>
      <Container className="conteudo-pagina mt-5">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col>
            <h3 className="mt-4 fw-bold">Servidores Cadastrados</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <p> Nesta seção, são listados todos os servidores cadastrados e ativos do seu setor. Para desativar um servidor, clique no ícone de usuário localizado na coluna "Desativar". Note que a desativação de um usuário é um processo irreversível.</p>
          </Col>
        </Row>

        <Row>
          <Col className="bg-light rounded p-3">
            <Form>
              <Row>
                <Col lg="7" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="Pesquisar por CPF do servidor:"
                  />
                </Col>
                <Col className="d-flex flex-row mt-3 justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Pesquisar por parâmetro selecionado</Tooltip>}>
                    <Button variant="primary" className="me-3" onClick={formik.handleSubmit}>
                        Pesquisar
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Limpar resultados e parâmetro da pesquisa</Tooltip>}>
                    <Button variant="link" className="ms-3" onClick={limparCampos}>
                      Limpar
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          {showSpinner && (
            <Row>
              <Col className="d-flex flex-row mt-5 justify-content-center">
                <h5 className="mb-2">
                  <Spinner animation="border" size="lg" variant="warning" />
                </h5>
              </Col>
            </Row>
          )}
          <Col>
            {Array.isArray(paginatedData) &&
              paginatedData.length > 0 &&
              !showSpinner && (
                <>
                  <div style={{ overflowX: 'auto' }}>
                    <Table striped bordered hover className="mt-4">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Email</th>
                          <th>Telefone Principal</th>
                          <th>Último acesso</th>
                          <th>Desativar</th>
                          <th>Reenviar link de ativação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.map((itemData) => (

                          <tr
                            key={itemData.id_usuario_dados}
                           
                          >
                            <td>{itemData.tx_nome}</td>
                            <td >{itemData.tx_login}</td>
                            <td>{FormatTelefone(itemData.tx_tel1)}</td>
                            <td>{FormatDate(itemData.tm_ultimo_acesso) ? FormatDate(itemData.tm_ultimo_acesso) : "O usuário ainda não realizou o primeiro acesso."}</td>

                            <td className="text-center mt-2">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip className="medium mt-1">Desativar Usuário</Tooltip>}>
                                <FaUserAltSlash
                                 style={{ cursor: "pointer" }}
                                  size={20}
                                  color="red"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowSpinner(true);
                                    setUserIdToDelete(itemData.id_usuario_servidor);
                                    setShowModal(true);
                                  }}
                                />
                              </OverlayTrigger>
                            </td>
                            <td className="text-center mt-2">
                              {!itemData.tm_token_validacao ?(
                                  <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip className="medium mt-1 ">Acessar página de reenvio de link para ativação cadastral</Tooltip>}>
                                 <Link to={`/reenviar/link/user/${itemData.id_usuario_dados}`}>
                                    <FaUserPen
                                      style={{ cursor: "pointer" }}
                                      size={23}
                                      color="blue"
                                    />
                                  </Link>
                                </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip className="medium mt-1">Usuário Ativado</Tooltip>}>
                                  <FaUserCheck
                                    size={23}
                                    color="green"
                                  />
                                </OverlayTrigger>
                                )}
                              </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {data && totalPages > 1 && (
                    <TabelPagination totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />)}
                </>
              )}
            {Array.isArray(paginatedData) &&
              paginatedData.length === 0 &&
              !showSpinner && (
                <Alert className="mt-2" variant="primary">
                  Nenhum registro encontrado
                </Alert>
              )}
          </Col>
        </Row>
      </Container>
      <ConfirmarExclusaoModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleConfirm={handleDeleteConfirm}
        handleCancel={handleCancelDelete}
        title="Confirmar Desativação"
        bodyText="Atenção: A desativação de um usuário é um processo irreversível. Você tem certeza de que deseja prosseguir com a desativação deste usuário?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
      />
    </>
  );
};

export default ListagemServidores;
