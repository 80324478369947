import React, { useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../../components/UI/Formik/TextInput/TextInput";
import SpinnerLabel from "../../../components/UI/SpinnerLabel";
import ViaCepLoader from "../../../components/UI/ViaCepLoader/ViaCepLoader";
import SelectInput from "../../../components/UI/Formik/SelectInput/SelectInput";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import {
  MunicipiosAsOptions,
  MunicipiosDoRN,
} from "../../../components/UI/Formik/SelectInput/SelectInput.options";
import { validateCPF } from "../../../components/UI/Formik/Validations/Validations";
import SelectInputCached from "../../../components/UI/Formik/SelectInput/SelectInput.cached";
import Inputmask from "inputmask";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer, Bounce } from "react-toastify";

const asciiEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = yup.object().shape({
  nome: yup.string().required("Informe seu nome completo "),
  login: yup.string().email("E-mail inválido, verifique seu email.").required("Informe o email do coordenador ").matches(asciiEmailRegex, 'O e-mail não deve conter caracteres especiais, como símbolos, acentos (á, ç, ü, etc.), letras fora do padrão inglês e espaço em branco.'),
  cpf: yup
    .string()
    .transform((originalValue) => {
      const cleanedValue = originalValue.replace(/[^\d]+/g, "");
      return cleanedValue;
    })
    .matches("[0-9]{11}", "Somente números de 0-9 e no máximo 11 dígitos")
    .test("cpf-validation", "CPF inválido.", (cpf) => {
      return validateCPF(cpf);
    })
    .required("Informe seu CPF."),
  logradouro: yup.string().required("Informe o logradouro de seu endereço "),
  numero: yup.string().required("Informe o número de seu endereço "),
  cidade: yup.string().required("Informe a cidade de seu endereço "),
  bairro: yup.string().required("Informe o bairro de seu endereço "),
  cep: yup
    .string()
    .transform((originalValue) => {
      const cleanCEP = originalValue.replace(/[^\d]+/g, "");
      return cleanCEP;
    })
    .matches("[0-9]{8}", "O cep deve conter até 8 digitos")
    .required("O CEP é um campo obrigátorio"),
  telefone1: yup
    .string()
    .transform((originalValue) => {
      const cleanedValue = originalValue.replace(/[^\d]+/g, "");
      return cleanedValue;
    })
    .matches("[0-9]{11}", "Somente números de 0-9. o número deve possuir 11 dígitos")
    .required("Informe DDD e telefone com  11 dígitos "),
  telefone2: yup
    .string()
    .transform((originalValue) => {
      const cleanedValue = originalValue.replace(/[^\d]+/g, "");
      return cleanedValue;
    })
    .matches("[0-9]{11}", "Somente números de 0-9. o número deve possuir 11 dígitos"),
  IdSetor: yup
    .string()
    .required("Por favor, selecione um setor que já tenha sido cadastrado."),
});

const initialValues = {
  nome: "",
  login: "",
  cpf: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "RN",
  cep: "",
  telefone1: "",
  telefone2: "",
  IdSetor: "",
};

const FormCriacaoCoordenador = () => {
  const { setBackgroundClassList } = useContext(AppContext);
  const history = useHistory();
  const numeroInputRef = useRef(null);

  const { request, data, errors, loading } = useApiClient();

  const handleSubmitCleanMask = (values) => {
    const cpfSemMascara = values.cpf.replace(/[^\d]+/g, "");
    const cepSemMascara = values.cep.replace(/[^\d]+/g, "");
    const tel1SemMascara = values.telefone1.replace(/[^\d]+/g, "");
    const tel2SemMascara = values.telefone2.replace(/[^\d]+/g, "");
    values.cpf = cpfSemMascara;
    values.cep = cepSemMascara;
    values.telefone1 = tel1SemMascara;
    values.telefone2 = tel2SemMascara;
    request(apiRoutes.main.coordenador.criarCoordenador, values, {
      requestId: "salvar",
    });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitCleanMask,
  });

  const {  setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    setBackgroundClassList(["background-1"]);
  });

  useEffect(() => {
    if (data) {
      toast.success("Coordenador registrado com sucesso!!! O coordenador deve acessar o link enviado para seu e-mail para definir sua senha e ativar o cadastro.", {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => {
          window.location.reload()
        },
      });
    }

    if (errors) {
      toast.error(
        errors.message ||
        "Desculpe algo deu errado, Tente novamente mais tarde!",
        {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
          onClose: () => { 
            setSubmitting(false);
    
          },
        }
      );
    }
  }, [data, errors, setSubmitting, history]);

  useEffect(() => {
    const CEPMask = Inputmask({
      mask: "99999-999",
      numericInput: true,
    });

    const CPFFormat = Inputmask({
      mask: "999.999.999-99",
      numericInput: true,
    });

    const telefoneMask = Inputmask({
      mask: ["(99) 9 9999-9999"],
      numericInput: true,
    });

    CPFFormat.mask(document.getElementById("cpf"));
    CEPMask.mask(document.getElementById("cep"));
    telefoneMask.mask(document.getElementById("telefone1"));
    telefoneMask.mask(document.getElementById("telefone2"));
  }, []);

  const readOnly = loading || formik.isSubmitting;

  return (
    <>
      <Container id="cabecalho-pagina" fluid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Row>
          <Col className="d-flex flex-column text-center mt-5">
            <h1 className="text-white text-shadow mt-5">
              Cadastro de Coordenadores
            </h1>
          </Col>
        </Row>
      </Container>

      <Container id="conteudo-pagina-interna">
      <Row>
          <div className="alert alert-info mt-5" role="alert">
                <h4 className="alert-heading">Instruções</h4>
                <p>Nesta página, você deverá fornecer todas as informações do coordenador que deseja cadastrar e relacionar a um setor. Após concluir o cadastro, o sistema enviará um e-mail ao coordenador, solicitando que ele defina sua senha e ative seu cadastro. Após essa etapa, ele poderá acessar sua conta </p>
                <p>Lembre-se de que cada setor pode ter apenas um único coordenador.</p>
                <p>Antes de cadastrar o coordenador, lembre-se de cadastrar o setor ou verificar se o setor já não possui um coordenador ativo.</p>
                <p>Você pode selecionar apenas setores cadastrados que não tenham nenhum coordenador ativo relacionado.</p>
          </div>
        </Row>
        <Row>
          <Col className="mt-5">
            <Form>
              <Row className="d-flex g-4">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h3 className="my-3">Dados Pessoais</h3>
                </Col>

                {formik.status && (
                  <Alert variant="danger">{formik.status}</Alert>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="nome"
                    label="Nome:"
                    placeholder="Informe o seu nome completo"
                    disabled={isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="login"
                    label="E-mail:"
                    placeholder="Informe o e-mail do coordenador."
                    disabled={isSubmitting}
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="cpf"
                    label="CPF:"
                    placeholder="Informe o seu CPF"
                    disabled={isSubmitting}
                  />
                </Col>

                <Col lg="3" md="3" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="cep"
                    label="CEP:"
                    disabled={isSubmitting}
                  />
                </Col>

                {!readOnly && (
                  <Col lg="3" md="3" sm="12" xs="12" style={{ alignSelf: "end" }}>
                    <ViaCepLoader
                      disabled={!formik.values.cep || !!formik.errors.cep}
                      cep={formik.values.cep}
                      onFound={(data) => {
                        const { logradouro, bairro, ibge } = data;
                        formik.setValues({
                          ...formik.values,
                          logradouro: logradouro,
                          bairro: bairro,
                          cidade: MunicipiosDoRN[ibge],
                        });
                        numeroInputRef.current.focus();
                      }}
                    />
                  </Col>
                )}

                <Col lg="10" md="10" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="logradouro"
                    label="Logradouro:"
                    placeholder="Logradouro do seu endereço"
                    maxLength={250}
                    disabled={isSubmitting}
                  />
                </Col>

                <Col lg="2" md="2" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    ref={numeroInputRef}
                    field="numero"
                    label="Número:"
                    placeholder="Nº Residência"
                    disabled={isSubmitting}
                    maxLength={5}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="complemento"
                    label="Complemento:"
                    placeholder="Complemento (Opcional)"
                    disabled={isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="bairro"
                    label="Bairro:"
                    placeholder="Informe o seu bairro"
                    disabled={isSubmitting}
                  />
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <SelectInput
                    formik={formik}
                    field="cidade"
                    label="Cidade:"
                    disabled={isSubmitting}
                  >
                    <MunicipiosAsOptions />
                  </SelectInput>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone1"
                    label="Telefone principal:"
                    placeholder="Informe o seu telefone primário"
                    disabled={isSubmitting}
                  />
                </Col>
                <Col lg="6" md="6" sm="12" xs="12">
                  <TextInput
                    formik={formik}
                    field="telefone2"
                    label="Telefone secundário:"
                    placeholder="Informe o seu telefone secundário"
                    disabled={isSubmitting}
                  />
                </Col>

                <Col lg="12" md="12" sm="12" xs="12">
                  <SelectInputCached
                    formik={formik}
                    field="IdSetor"
                    label="Selecione o setor previamente cadastrado:"
                    cacheKey="setoresSemCoordenadores"
                    disabled={isSubmitting}
                    defaultText="Selecione o setor."
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center mt-5">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Salvar no sistema</Tooltip>}>
                    <Button
                      className="mt-3"
                      disabled={isSubmitting}
                      onClick={formik.handleSubmit}
                    >
                      <SpinnerLabel
                        loading={formik.isSubmitting}
                        label="Salvar"
                      />
                    </Button>
                  </OverlayTrigger>
                  &nbsp;&nbsp;
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className="medium mt-1">Voltar para página anterior</Tooltip>}>
                    <Button className="mt-3" onClick={() => history.goBack()} disabled={isSubmitting} >
                      Voltar
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormCriacaoCoordenador;
